<template>
    <Stack :space="20">
        <Stack :space="20">
            <Columns>
                <Column />
                <Column>
                    <Heading class="mb-3" level="2" color="blue">Epost</Heading>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack :space="10" style="width: 100%">
                        <TextBase size="medium" weight="bold">To: </TextBase>
                        <ClientsSearch @on-selected="onClientChange" :selected="selectedClient" style="width: 100%" drop-down-width="532px" />
                    </Stack>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack :space="10" style="width: 100%">
                        <TextBase size="medium" weight="bold">Epost adress: </TextBase>
                        <Input name="Subject" style="width: 100%" :value="emailObj.toEmailAddress" disabled v-model="emailObj.toEmailAddress" />
                    </Stack>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack :space="10" style="width: 100%">
                        <TextBase size="medium" weight="bold">Ämne: </TextBase>
                        <Input name="Subject" style="width: 100%" :value="emailObj.subject" v-model="emailObj.subject" />
                    </Stack>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack :space="10" style="width: 100%">
                        <TextBase size="medium" weight="bold">Meddelande: </TextBase>
                        <TextArea style="width: 100%" size="medium" weight="light" v-model="emailObj.body" />
                    </Stack>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack :space="10" style="width: 100%">
                        <TextBase size="medium" weight="bold">Från: </TextBase>
                        <Input name="From" style="width: 100%" :value="emailObj.from" v-model="emailObj.from" />
                    </Stack>
                </Column>
                <Column />
            </Columns>
            <Columns>
                <Column />
                <Column>
                    <Stack align="right">
                        <button class="button is-success is-small is-rounded" @click="sendEmail()" :class="[{ 'is-loading': loading }]">
                            Skicka epost
                        </button>
                    </Stack>
                </Column>
                <Column />
            </Columns>
        </Stack>
    </Stack>
</template>
<script>
import Stack from "../../components/layout/Stack.vue";
import Heading from "../../components/content/Heading.vue";
import Columns from "../../components/layout/Columns.vue";
import Column from "../../components/layout/Column.vue";
import TextBase from "../../components/content/Text.vue";
import TextArea from "../../components/textArea.vue";
import Input from "../../components/baseInput";
import Api from "../epost/email.api";
export default {
    components: {
        Stack,
        Heading,
        Columns,
        Column,
        TextBase,
        Input,
        TextArea,
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },
    data() {
        return {
            emailObj: { to: [], from: "help@bolageriet.se", subject: "", body: "", toEmailAddress: "" },
            clients: [],
            loading: false,
            selectedClient: {},
        };
    },
    async beforeCreate() {
        this.clients = await Api.getClients();
    },
    methods: {
        resetEmailObj() {
            this.emailObj.to = [];
            this.emailObj.subject = "";
            this.emailObj.body = "";
            this.selectedClient = undefined;
        },
        onClientChange: async function(item) {
            var email = await Api.getClientEmail(item.value);
            this.selectedClient = item;
            this.emailObj.to[0] = item.value;
            this.emailObj.toEmailAddress = email;
        },
        async sendEmail() {
            this.loading = true;

            if (this.emailObj.to.length > 0) {
                var result = await Api.sendEmail(this.emailObj);

                if (result != undefined) {
                    const type = result == true ? "success" : "error";
                    const message = result == true ? "E-postmeddelandet skickades" : "Fel vid skicka e-post";

                    this.$toasted.show(message, {
                        theme: "bubble",
                        duration: 4000,
                        position: "bottom-center",
                        type: type,
                        keepOnHover: true,
                    });
                    if (result == true) {
                        this.resetEmailObj();
                    }
                    this.loading = false;
                }
            } else {
                this.$toasted.show("Välj klient", {
                    theme: "bubble",
                    duration: 3000,
                    position: "bottom-center",
                    type: "info",
                    keepOnHover: true,
                });
                this.loading = false;
            }
        },
    },
};
</script>
