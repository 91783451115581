<template>
    <div class="event-item-container">
        <div class="event-details-container" :class="{ 'child-events-open': showingChildEvents }">
            <div @click="handleEventClick(event)" class="event-box">
                <div class="event-box-content">
                    <i :class="[iconName, 'event-icon']" :style="{ backgroundColor: iconColor }" />
                    <div class="event-description-container">
                        <span v-if="eventDate" class="event-date">{{ eventDate }}</span>
                        <span class="event-title">{{ eventText }}</span>
                        <span v-if="eventUser" class="event-user-label">
                            {{ eventUser }}
                        </span>
                    </div>
                </div>
            </div>
            <i v-if="childEvents.length" @click="handleChildEvents" class="fa-solid fa-chevron-down child-event-dropdown-arrow dropdown-arrow" :class="{ 'rotated-arrow': showingChildEvents }" />
        </div>
        <transition v-if="childEvents.length" name="slide">
            <div v-show="showingChildEvents" class="child-events">
                <div class="child-event-details-container">
                    <div @click="viewDetails(event)" v-for="(event, index) in childEvents" :key="index" class="event-box">
                        <i :class="[event.icon, 'event-icon-child-event']" :style="{ backgroundColor: event.iconColor }" />
                        <div class="child-event-description-container">
                            <span class="event-title">{{ event.text }}</span>
                            <span class="event-user-label">{{ event.adminUserName === "System" ? event.clientUserName : event.adminUserName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        childEvents: {
            type: Array,
            required: false,
        },
        eventText: {
            type: String,
            required: false,
        },
        iconName: {
            type: String,
            required: false,
        },
        iconColor: {
            type: String,
            required: false,
        },
        eventUser: {
            type: String,
            required: false,
        },
        eventDate: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            showingChildEvents: false,
        };
    },
    methods: {
        handleEventClick() {
            if (this.childEvents && this.childEvents.length > 0) {
                this.handleChildEvents();
            } else {
                this.viewDetails(this.event);
            }
        },
        viewDetails(event) {
            this.$emit("viewDetails", { id: event.auditLogId, eventTitle: event.text });
        },
        handleChildEvents() {
            this.showingChildEvents = !this.showingChildEvents;
        },
    },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    max-height: 0;
    padding: 0;
}

.slide-enter-to,
.slide-leave {
    max-height: 500px;
    padding: 0;
}
.child-events {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.child-event-details-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 90%;
}
.event-details-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.event-details-container.child-events-open {
    background-color: #f5f5f5;
    border-radius: 5px;
    .child-event-dropdown-arrow {
        display: inline-block;
        color: #1d3f67;
    }
}
.event-details-container:hover {
    background-color: #e0f7fa;
    border-radius: 5px;
    cursor: pointer;
    .child-event-dropdown-arrow {
        display: inline-block;
        color: #1d3f67;
    }
}
.event-item-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.event-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1em;
    width: 100%;
}
.event-box:hover {
    cursor: pointer;
    .child-event-dropdown-arrow {
        display: inline-block;
        color: #1d3f67;
    }
}

.child-events .event-box {
    background-color: #f9f9f9;
    width: 100%;
    padding: 0.5em;
    border-radius: 5px;
    justify-content: start;
    position: relative;
}
.child-events .event-box:hover {
    cursor: pointer;
    background-color: #e0f7fa;
    border-radius: 5px;
}
.child-event-dropdown-arrow {
    display: none;
    position: absolute;
    right: 1.5em;
    display: none;
    color: #1d3f67;
    cursor: pointer;
    padding: 0.5em;
}
.dropdown-arrow {
    transition: transform 0.2s;
    padding: 0.5em;
}

.rotated-arrow {
    padding: 0.5em;
    transform: rotate(180deg);
}

.event-box-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}
.event-icon {
    border-radius: 50%;
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}
.event-icon-child-event {
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: #e0f7fa;
}

.event-description-container {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    width: 100%;
}
.child-event-description-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.child-events .event-title {
    font-size: 11px;
    font-weight: 500;
    color: #1d3f67;
}
.event-title {
    color: #1d3f67;
    font-weight: bold;
    font-size: 12px;
    word-break: break-word;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.child-events .event-user-label {
    font-size: 11px;
}
.event-user-label {
    color: #7a7a7a;
    font-size: 12px;
    font-weight: normal;
}
.event-date {
    width: 95%;
    color: #9ba5b1;
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
}
</style>
