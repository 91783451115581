import Vue from "vue";

export default {
    async getAuditLogs(dateFrom, dateTo, pageNumber, pageSize, search, groupTypes, sourceTypes, clientId) {
        const params = {
            dateFrom,
            dateTo,
            pageNumber,
            pageSize,
            search,
            groupTypes: groupTypes.join(","),
            sourceTypes: sourceTypes.join(","),
            clientId,
        };
        const response = await Vue.axios.get(`logs`, { params });
        return response;
    },
    async getAuditLogsEvents(dateFrom, dateTo, pageNumber, pageSize, search, groupTypes, sourceTypes, clientId) {
        const params = {
            dateFrom,
            dateTo,
            pageNumber,
            pageSize,
            search,
            groupTypes: groupTypes.join(","),
            sourceTypes: sourceTypes.join(","),
            clientId,
        };
        const response = await Vue.axios.get(`logs/events`, { params });
        return response;
    },

    async getAuditLog(auditLogId) {
        const response = await Vue.axios.get(`logs/${auditLogId}`);
        return response;
    },
};
