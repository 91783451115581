<template>
    <Stack>
        <Columns :space="20">
            <Column :width="50" v-if="clientsMaped && render">
                <Stack>
                    <Stack style="width: 100%">
                        <Columns align-y="center">
                            <Stack>
                                <TextBase class="pl-3">Företagstyp:</TextBase>
                                <DropDown :options="companyTypes" state="ready" v-model="companyType" style="margin-left: 10px; width: 100%" />
                            </Stack>
                            <Stack>
                                <TextBase class="pl-3">Startmånad:</TextBase>
                                <DropDown :options="months" state="ready" v-model="filterMonth" style="margin-left: 10px; width: 100%" />
                            </Stack>
                        </Columns>
                        <Columns align-y="center">
                            <Stack>
                                <TextBase class="pl-3">Bokslutsdatum:</TextBase>
                                <DateInputField class="pl-3" ref="dateInputField" @change="filterEndDate = $event" style="width: 50%;" />
                            </Stack>
                        </Columns>
                        <Columns align-y="center">
                            <Stack>
                                <TextBase class="pl-3">Bankvy:</TextBase>
                                <DropDown :options="yesno" state="ready" v-model="bankView" style="margin-left: 10px; width: 100%" />
                            </Stack>
                            <Stack>
                                <TextBase class="pl-3">Bank:</TextBase>
                                <DropDown :options="banks" state="ready" v-model="filterBank" style="margin-left: 10px; width: 100%" />
                            </Stack>
                        </Columns>
                        <Columns align-y="center">
                            <Stack class="w-1/2">
                                <TextBase class="pl-3">Momsperiod:</TextBase>
                                <DropDown :options="vatPeriods" state="ready" v-model="vatPeriod" style="margin-left: 10px; width: 100%" />
                            </Stack>
                            <Stack>
                                <TextBase class="pl-3">Bokslut:</TextBase>
                                <DropDown :options="finalAccounts" state="ready" v-model="finalAccount" style="margin-left: 10px; width: 100%;" />
                            </Stack>
                        </Columns>
                    </Stack>
                    <Columns align-y="center">
                        <Stack style="width: 50%">
                            <TextBase class="pl-3">Antal = {{ clientsMaped.length }}</TextBase>
                        </Stack>
                        <Stack style="width: 50%" align="right">
                            <button v-if="true" class="button is-info is-small is-rounded" @click="selectClients">
                                Välj klienter
                            </button>
                        </Stack>
                    </Columns>
                    <List :items="clientsMaped" :scroll="true" :search="false" :headers="headers" style="font-size: 14px;" />
                </Stack>
            </Column>
            <Column style="align-self: normal;" v-if="selectedClientIds.length > 0">
                <Columns style="margin-left: 10rem" aligns="center" align-y="center">
                    <Stack :space="20">
                        <Stack :space="20" style="width: 100%">
                            <TextBase size="medium" weight="bold">Ämne: </TextBase>
                            <Input label="subject" name="Subject" :value="emailObj.subject" v-model="emailObj.subject" style="width: 60%" />
                        </Stack>
                        <Stack :space="20" style="width: 100%">
                            <TextBase size="medium" weight="bold">Meddelande: </TextBase>
                            <TextArea style="width: 60%" size="medium" weight="light" v-model="emailObj.body" />
                        </Stack>
                        <Stack :space="20" style="width: 100%">
                            <TextBase size="medium" weight="bold">Från: </TextBase>
                            <Input label="subject" name="Subject" :value="emailObj.from" v-model="emailObj.from" style="width: 60%" />
                        </Stack>
                        <Stack style="width: 60%" align="right">
                            <button style="margin: 10px 0 0 10px;" v-if="true" class="button is-info is-small is-rounded" @click="sendEmail" :class="[{ 'is-loading': loading }]">
                                Skicka epost
                            </button>
                        </Stack>
                    </Stack>
                </Columns>
            </Column>
        </Columns>
    </Stack>
</template>
<script>
import Stack from "../../components/layout/Stack.vue";
import Columns from "../../components/layout/Columns.vue";
import Column from "../../components/layout/Column.vue";
import Input from "../../components/baseInput";
import TextBase from "../../components/content/Text.vue";
import TextArea from "../../components/textArea.vue";
import List from "../../components/list2";
import DropDown from "../../components/dropdown";
//import moment from "moment";

import Api from "../epost/email.api";
export default {
    components: {
        Stack,
        Columns,
        Column,
        Input,
        TextBase,
        TextArea,
        List,
        DropDown,
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },
    data: function() {
        return {
            loading: false,
            render: true,
            emailObj: { to: [], from: "help@bolageriet.se", subject: "", body: "" },
            clientList: [],
            selectedClientIds: [],
            showOnlyVatQuarter: false,
            months: [
                { name: "Alla", value: 0 },
                { name: "Jan", value: 1 },
                { name: "Feb", value: 2 },
                { name: "Mar", value: 3 },
                { name: "Apr", value: 4 },
                { name: "Maj", value: 5 },
                { name: "Jun", value: 6 },
                { name: "Jul", value: 7 },
                { name: "Aug", value: 8 },
                { name: "Sep", value: 9 },
                { name: "Okt", value: 10 },
                { name: "Nov", value: 11 },
                { name: "Dec", value: 12 },
            ],
            filterMonth: 0,
            filterEndDate: "",
            banks: [
                { name: "Alla", value: "all" },
                { name: "Ingen", value: "no" },
                { name: "Nordea", value: "nordea" },
                { name: "Swedbank", value: "swedbank" },
                { name: "Sparbanken", value: "sparbank" },
                { name: "SEB", value: "seb" },
                { name: "Länsförsäkringar", value: "länsförsäkringar" },
                { name: "Skandiabanken", value: "skandia" },
                { name: "ICA-banken", value: "ica" },
                { name: "Danske bank", value: "danske" },
                { name: "Handelsbanken", value: "handelsbanken" },
                { name: "SBAB", value: "sbab" },
            ],
            companyTypes: [
                { name: "Alla", value: 0 },
                { name: "Aktiebolag", value: 5 },
                { name: "Enskild firma", value: 1 },
                { name: "Handelsbolag", value: 2 },
            ],
            filterBank: "all",
            companyType: 0,
            vatPeriods: [
                { name: "Alla", value: 0 },
                { name: "År", value: 1 },
                { name: "År (26 feb)", value: 2 },
                { name: "Kvartal", value: 3 },
                { name: "Månad 12:e", value: 4 },
                { name: "Månad 26:e innan", value: 6 },
                { name: "Ej momsregistrerad", value: 5 },
            ],
            vatPeriod: 0,
            finalAccounts: [
                { name: "Alla", value: 0 },
                { name: "Ja", value: 1 },
                { name: "Nej", value: 2 },
            ],
            yesno: [
                { name: "Av", value: 0 },
                { name: "På", value: 1 },
            ],
            bankView: 0,
            finalAccount: 0,
        };
    },
    async beforeCreate() {
        this.clientList = await Api.getClientList();
    },
    methods: {
        onMonthChange() {},
        resetEmailObj() {
            this.render = false;
            this.emailObj.to = [];
            this.emailObj.subject = "";
            this.emailObj.body = "";
            this.selectedClientIds = [];
            this.clientsMaped.forEach(client => {
                this.$set(client, "check", false);
            });
            this.$nextTick(() => {
                // Add the component back in
                this.render = true;
            });
        },
        selectClients() {
            var selectedClientsDetails = this.clientsMaped.filter(item => item.check === true);
            this.selectedClientIds = selectedClientsDetails.map(a => a.id);
            this.emailObj.to = this.selectedClientIds;
        },
        async sendEmail() {
            this.loading = true;
            if (this.emailObj.to.length > 0) {
                var result = await Api.sendEmail(this.emailObj);
                if (result != undefined) {
                    const type = result == true ? "success" : "error";
                    const message = result == true ? "E-postmeddelandet skickades" : "Fel vid skicka e-post";

                    this.$toasted.show(message, {
                        theme: "bubble",
                        duration: 4000,
                        position: "bottom-center",
                        type: type,
                        keepOnHover: true,
                    });
                    if (result == true) {
                        this.resetEmailObj();
                    }
                    this.loading = false;
                }
            } else {
                this.$toasted.show("Välj klient", {
                    theme: "bubble",
                    duration: 3000,
                    position: "bottom-center",
                    type: "info",
                    keepOnHover: true,
                });
                this.loading = false;
            }
        },
    },
    computed: {
        headers() {
            let headers = [];
            headers.push({ name: "", prop: "check", width: "20px", check: true });
            headers.push({ name: "Klientnummer", prop: "clientnumber", width: "50px" });
            headers.push({ name: "Företag", prop: "name" });
            headers.push({ name: "Epost", prop: "email" });
            headers.push({ name: "Bokslut", prop: "endDate" });
            if (this.bankView == 1) {
                headers.push({ name: "Bank", prop: "bank" });
                headers.push({ name: "Synk synlig", prop: "bankSyncShow" });
                headers.push({ name: "Aktiv synk", prop: "unattended" });
                headers.push({ name: "Aldrig synkat", prop: "bankSync" });
                headers.push({ name: "Medgivande utgått", prop: "consentDate" });
            }
            return headers;
        },
        clientsFiltered1: function() {
            if (this.clientList != undefined) {
                return this.clientList.filter(item => item.companyType == this.companyType || this.companyType == 0);
            }
            return null;
        },
        clientsFiltered2: function() {
            if (this.clientsFiltered1 != undefined) {
                return this.clientsFiltered1.filter(item => item.startMonth == this.filterMonth || this.filterMonth == 0);
            }
            return null;
        },
        clientsFiltered21: function() {
            if (this.clientsFiltered2 != undefined) {
                return this.clientsFiltered2.filter(item => item.endDate == this.filterEndDate || !this.filterEndDate);
            }
            return null;
        },
        clientsFiltered3: function() {
            if (this.clientsFiltered21 != undefined) {
                return this.clientsFiltered21.filter(item => item.vatPeriod == this.vatPeriod || this.vatPeriod == 0);
            }
            return null;
        },
        clientsFiltered4: function() {
            if (this.clientsFiltered3 != undefined) {
                return this.clientsFiltered3.filter(item => {
                    if (this.finalAccount == 0) {
                        return true;
                    }
                    if (this.finalAccount == 1 && item.endDateClose) {
                        return true;
                    }
                    if (this.finalAccount == 2 && !item.endDateClose) {
                        return true;
                    }
                    return false;
                });
            }
            return null;
        },
        clientsFiltered5: function() {
            if (this.clientsFiltered4 != undefined) {
                return this.clientsFiltered4.filter(item => {
                    return (
                        (this.filterBank == "no" && item.bank == null) ||
                        this.filterBank == "all" ||
                        (this.filterBank != "no" && item.bank != null && item.bank.toLowerCase().includes(this.filterBank))
                    );
                });
            }
            return null;
        },
        clientsMaped: function() {
            if (this.clientsFiltered5 != undefined) {
                return this.clientsFiltered5.map(item => ({
                    id: item.clientId,
                    clientnumber: item.number,
                    name: item.name,
                    email: item.email,
                    bank: item.bank,
                    bankSyncShow: item.bankSyncShow,
                    bankSync: !item.bankSync,
                    unattended: item.unattended,
                    consentDate: item.consentDate,
                    endDate: item.endDate,
                    check: false,
                }));
            }
            return null;
        },
    },
};
</script>
